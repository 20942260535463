<template>
    <div  class="payments-container" :class="{show_payments:data.show_payments_window, hide_payments:data.hide_payments_window}">
        <div @click.stop="close_payments_window()" class="title">Payments for {{data.payments_student.ui}}</div>
        <div @click.stop class="payments">
            <button @click.stop="create_payment(data.payments_student)">
                New Payment
            </button>

            <button @click.stop="get_null_attendances(data.payments_student)" >
                Get Null Attendances
            </button>




            <div :class="{show_payment:payment.show, hide_payment:payment.hide, paid:payment.paid}" class="payment" v-for="(payment,payment_index) in data.student_payments">
                <button @click.stop="toggle_payment(payment)" class="subtitle">
                    Payment {{payment_index+1}}
                </button>
                <button @click.stop="remove_payment(payment)">
                    <span class="material-symbols-outlined">
                        remove
                    </span>
                </button>
                <template v-if="payment.show">
                    <div @click.stop class="payment-info">
                        <table>
                            <thead>
                                <th>Datetime</th>
                                <th>Plan</th>
                                <th>Amount</th>
                                <th>Method</th>
                                <th>Paid</th>
                                <th>Reference</th>
                                <th>Status</th>
                                <th>Comments</th>
                            </thead>
                            <tbody>
                                <tr>
                                    <td><input @change.stop="patch(payment,'datetime')" type='datetime-local' v-model="payment.datetime"></td>
                                    <td><input @change.stop="patch(payment,'plan')" v-model="payment.plan"></td>
                                    <td><input @change.stop="patch(payment,'amount')" v-model="payment.amount"></td>
                                    <td><input @change.stop="patch(payment,'method')" v-model="payment.method"></td>
                                    <td><input type="checkbox" @change.stop="patch(payment,'paid')" v-model="payment.paid"></td>
                                    <td><input @change.stop="patch(payment,'reference')" v-model="payment.reference"></td>
                                    <td><input @change.stop="patch(payment,'status')" v-model="payment.status"></td>
                                    <td><input @change.stop="patch(payment,'comments')" v-model="payment.comments"></td>
        
                                </tr>
                            </tbody>
                        </table>
                    </div>

                    <hr>
                    <button @click.stop="create_package(payment,data.payments_student)">
                        New Package
                    </button>

                    <template v-for="(package_,package_index) in payment.package">
                        <div @click.stop  class="package-container">
                            <div class="subtitle">Package {{package_index+1}}</div>
                            <button @click.stop="remove_package(payment,package_)">
                                Remove Package
                            </button>
                            <div class="package-hours">
                                <label>
                                    Taken Hours
                                    <input @change.stop="patch(package_, 'taken_hours')" type="number" step="0.01" min="0" v-model="package_.taken_hours" >
                                </label>
                                <label>
                                    Package Hours
                                    <input @change.stop="patch(package_, 'package_hours')" type="number" step="0.01" min="0" v-model="package_.package_hours" >
                                </label>
    
                            </div>
                            <button @click.stop="create_attendance(package_)">
                                Create Attendance
                            </button>

                                
                                <table>
                                    <thead>
                                        <th>Admin Check</th>
                                        <th>Payment/Package</th>
                                        <th>Group</th>
                                        <th>Datetime</th>
                                        <th>Instructor Confirmation</th>
                                        <th>Student Confirmation</th>
                                        <th>Duration</th>
                                        <th>Link</th>
                                        <th>Comments</th>
                                        <th>…Options…</th>
                                    </thead>
                                    <tbody>
                                        <tr v-for="attendance in package_.attendance">
                                            <td><input @change.stop="patch(attendance,'admin_check')" type="checkbox" v-model="attendance.admin_check"></td>
                                            <td>
                                                <select @change.stop="change_payment_package($event,payment,package_,attendance)">
                                                    <template v-for="(paym,paym_index) in data.student_payments">
                                                        <template v-for="(pack,pack_index) in paym.package">
                                                            <template v-if="pack.id==package_.id">
                                                                <option :value="`${paym.id} | ${pack.id}`"  selected> Payment {{paym_index+1}} | Package {{  pack_index+1 }}</option>
                                                            </template>
                                                            <template v-else>
                                                                <option :value="`${paym.id} | ${pack.id}`"> Payment{{paym_index+1}} | Package {{  pack_index+1 }}</option>
                                                            </template>
                                                        </template>
                                                    </template>
                                                </select>
                                            </td>
                                            <td>Group here
                                                <select @change.stop="patch(attendance,'group')">
                                                    <template v-if="attendance.group">
                                                        <template v-for="group in data.payments_student.groups">
                                                            <template v-if="group">
                                                                <template v-if="group.id=attendance.group.id">
                                                                    <option selected>{{ group.id }}</option>
                                                                </template>
                                                                <template v-else>
                                                                    <option>{{ group.id }}</option>
                                                                </template>
                                                            </template>
                                                        </template>
                                                    </template>
                                                </select>
                                            </td>
                                            <td><input @change.stop="patch(attendance,'datetime')" type="datetime-local" v-model="attendance.datetime"></td>
                                            <td>
                                                <button @click.stop="create_instructor_confirmation(attendance)">
                                                    Create instructor confirmations
                                                </button>
                                                <table>
                                                    <tbody>
                                                        <tr v-for="instructor_confirmation in attendance.instructor_confirmation">
                                                            <td>
                                                                {{instructor_confirmation.id}}
                                                            </td>
                                                            <td>
                                                                <template v-if="instructor_confirmation.instructor">
                                                                    <select @click.stop="patch_instructor($event,instructor_confirmation,'instructor')">
                                                                        <option value=""></option>
                                                                        <template v-for="instructor in data.payment_instructors">
                                                                            <template v-if="instructor.id==instructor_confirmation.instructor.id">
                                                                                <option selected  :value="instructor.id">{{instructor.ui}}</option>
                                                                            
                                                                            </template>
                                                                            <template v-else>
                                                                                <option  :value="instructor.id">{{instructor.ui}}</option>
    
                                                                            </template>
        
                                                                        </template>
        
                                                                    </select>
                                                                </template>
                                                                <template v-else>
                                                                    <select @click.stop="patch_instructor($event,instructor_confirmation,'instructor')">
                                                                        <option value=""></option>
                                                                        <template v-for="instructor in data.payment_instructors">
                                                                            <option :value="instructor.id">{{instructor.ui}}</option>
                                                                        </template>
                                                                    </select>
                                                                </template>
                                                            </td>
                                                            <td>
                                                                <select @change.stop="patch(instructor_confirmation,'confirmation')" v-model=instructor_confirmation.confirmation>                                                                
                                                                    <option v-for="confirmation in confirmations">{{ confirmation }}</option>
                                                                </select>
                                                            </td>
                                                            <td>
                                                                <button @click.stop="remove_instructor_confirmation(attendance,instructor_confirmation)">
                                                                    Remove instructor confirmation
                                                                </button>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </td>
                                            <td> 
                                                <button @click.stop="create_student_confirmation(attendance)">
                                                    Create Student Confirmations
                                                </button>
                                                <table>
                                                    <tbody>
                                                        <tr v-for="student_confirmation in attendance.student_confirmation">
                                                            <td>
                                                                {{ student_confirmation.id }}
                                                            </td>
                                                            <td>
                                                                <template v-if="student_confirmation.student">
                                                                    
                                                                    <select @click.stop="patch_student($event,student_confirmation,'student')">
                                                                        <option value=""></option>
                                                                        <template v-for="student in data.payment_students">
                                                                            <template v-if="student.id==student_confirmation.student.id">
                                                                                <option selected  :value="student.id">{{student.ui}}</option>
                                                                            
                                                                            </template>
                                                                            <template v-else>
                                                                                <option  :value="student.id">{{student.ui}}</option>
    
                                                                            </template>
        
                                                                        </template>
        
                                                                    </select>
    
    
    
                                                                </template>
    
                                                                <template v-else>
                                                                    <select @click.stop="patch_student($event,student_confirmation,'student')">
                                                                        <option value=""></option>
                                                                        <template v-for="student in data.payment_students">
                                                                            <option :value="student.id">{{student.ui}}</option>
                                                                        </template>
                                                                    </select>
    
    
                                                                </template>
                                                            </td>
                                                            <td>
                                                                <select @change.stop="patch(student_confirmation,'confirmation')" v-model=student_confirmation.confirmation>
                                                                    
                                                                    <option v-for="confirmation in confirmations">{{ confirmation }}</option>
                                                                </select>
                                                            </td>
                                                            <td>
                                                                <button @click.stop="remove_student_confirmation(attendance,student_confirmation)">
                                                                    Remove Student Confirmation
                                                                </button>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </td>
                                            <td><input @change.stop="patch(attendance,'duration')" type="number" step="0.01" v-model="attendance.duration"></td>
                                            <td><input @change.stop="patch(attendance,'link')" v-model="attendance.link"></td>
                                            <td><input @change.stop="patch(attendance,'comments')" v-model="attendance.comments"></td>
                                            <td>
                                                <button @click.stop="remove_attendance(package_,attendance)">
                                                    Delete Attendance
                                                </button>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                        </div>
    
                    </template>
                </template>


            </div>
            

        </div>

    </div>


</template>

<script setup>
    import {reactive,ref, inject} from 'vue'
    import axios from 'axios'
    import {useStore} from 'vuex'
    
    
    let data=inject('data')

    let store=useStore()


    let confirmations=ref(['pending','confirmed','cancelled-late','no-show','rescheduled','cancelled-on-time'])

    function close_payments_window(){
        data.hide_payments_window=true
        setTimeout(()=>{
            data.show_payments_window=false
            data.student_payments={}
            data.payments_student={}
        },500)
    }



    async function create_payment(student){
        let response=await axios.post('/payments/create_payment/',{student_id:student.id})
        let new_payment=response.data
        data.student_payments.unshift(new_payment)
    }

    async function remove_payment(payment){
        let confirm_delete=await store.dispatch('confirm_delete',{object:payment})
        if (!confirm_delete){
            return
        }
        let response=await axios.post('/payments/remove_payment/',{payment_id:payment.id})
        for (let paym of data.student_payments){
            if(paym.id==payment.id){
                const index=Object.values(data.student_payments).indexOf(paym)
                data.student_payments.splice(index,1)
                break
            }

        }
    }


    async function get_null_attendances(student){
        let response= await axios.post('/payments/get_null_attendances/',{student_id:student.id})
        let null_payment=response.data
        data.student_payments.push(null_payment)
    }


    async function create_package(payment){
        let response=await axios.post('/payments/create_package/',{payment_id:payment.id,student_id:data.payments_student.id})
        let new_package=response.data
        payment.package.unshift(new_package)

    }

    async function remove_package(payment,package_){
        let confirm_delete=await store.dispatch('confirm_delete',{object:package_})
        if (!confirm_delete){
            return
        }


        let response=await axios.post('/payments/remove_package/',{package_id:package_.id})
        for(let pack of payment.package){

            if (pack.id==package_.id){
                const index=Object.values(payment.package).indexOf(pack)
                payment.package.splice(index,1)

            }
        }
    }


    async function create_attendance(package_){
        let response= await axios.post('/payments/create_attendance/',{package_id:package_.id})
        let attendance=response.data
        package_.attendance.unshift(attendance)

    }


    async function remove_attendance(package_,attendance){

        let confirm_delete=await store.dispatch('confirm_delete',{object:attendance})
        if (!confirm_delete){
            return
        }


        let response=await axios.post('/payments/remove_attendance/',{attendance_id:attendance.id})
        for (let att of package_.attendance){
            if (att.id==attendance.id){
                const index=Object.values(package_.attendance).indexOf(att)
                package_.attendance.splice(index,1)
            }
        }

    }

 async function change_payment_package(event,payment,package_,attendance){

        let payment_package_string=event.target.value

        let [payment_id,package_id]=payment_package_string.split(' | ')

        let response=await axios.post('/payments/change_payment_package/',{old_payment_id:payment.id,old_package_id:package_.id,payment_id:payment_id,package_id:package_id,attendance_id:attendance.id})
        
        package_.attendance = package_.attendance.filter(att => att.id !== attendance.id);


        for (let paym of data.student_payments){
            if (paym.id == payment_id){
                for(let pack of paym.package){
                    if(pack.id==package_id){
                        pack.attendance.unshift(attendance)
                        break
                    }
                }
                break
            }
        }

        
        for (let option of event.target.options){
            let value=option.value
            let [paym_id,pack_id]=value.split(' | ')
            if (paym_id==payment.id && pack_id==package_.id){
                option.selected=true
            }
        }


    }




    async function create_instructor_confirmation(attendance){
        let response=await axios.post('/payments/create_instructor_confirmation/',{group_id:data.emergency_group.id,attendance_id:attendance.id})

        let instructor_confirmation=response.data

        attendance.instructor_confirmation.push(instructor_confirmation)

    }

    async function remove_instructor_confirmation(attendance,instructor_confirmation){


        let confirm_delete=await store.dispatch('confirm_delete',{object:instructor_confirmation})
        if (!confirm_delete){
            return
        }


        let response = await axios.post('/payments/remove_instructor_confirmation/',{instructor_confirmation_id:instructor_confirmation.id})


        attendance.instructor_confirmation=attendance.instructor_confirmation.filter(inst_conf=>inst_conf.id!=instructor_confirmation.id)


    }

    async function create_student_confirmation(attendance){
        let response=await axios.post('/payments/create_student_confirmation/',{group_id:data.emergency_group.id,attendance_id:attendance.id})

        let student_confirmation=response.data

        // attendance.student_confirmation.push(student_confirmation)

        Object.assign(attendance.student_confirmation,response.data)



    }

    async function remove_student_confirmation(attendance,student_confirmation){

        let confirm_delete=await store.dispatch('confirm_delete',{object:student_confirmation})
        if (!confirm_delete){
            return
        }


        let response=await axios.post('/payments/remove_student_confirmation/',{student_confirmation_id:student_confirmation.id})


        attendance.student_confirmation=attendance.student_confirmation.filter(stud_conf=>stud_conf.id!=student_confirmation.id)

    }


    async function patch_instructor(event,element,key){
        let value=event.target.value
        let response=await axios.patch('/payments/patch/',{object:element,key:key,value:value}) 
        Object.assign(element,response.data)
    }

    async function patch_student(event,element,key){
        let value=event.target.value
        let response=await axios.patch('/payments/patch/',{object:element,key:key,value:value}) 
        Object.assign(element,response.data)
    }

    async function patch(element,key){
        let value=element[key]
        let response=await axios.patch('/payments/patch/',{object:element,key:key,value:value}) 
        Object.assign(element,response.data)
    }

    async function toggle_payment(payment){
        if (payment.show){
            payment.hide=true
            setTimeout(()=>{
                payment.show=false
            },500)
        }

        else{
            let response=await axios.post('/payments/get_packages/',{payment_id:payment.id})
            payment.package=response.data
            payment.show=true
            payment.hide=false
        }
        

    }


    

</script>

<style scoped>

.payments-container{
    position:fixed;
    display:flex;
    flex-direction:column;
    justify-content:start;
    align-items:center;
    color:var(--black);
    background:var(--white);
    width: 0;
    height: 0;
    top:50%;
    left:50%;
    transform:translate(-50%,-50%);
    font-size: 0;
    margin:0;
    padding:0;
    overflow:scroll;

}


@media (max-width:1500px){
    .payments-container{
        align-items:start;
    }

    .payment{
        align-items:start
    }

    table{
        align-self: start;
    }
}



.payments{
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: center;
    gap:5px;

    width:100%;

}

.payment{
    width:150px;
    height:100px;
    display:flex;
    flex-direction:column;
    justify-content:start;
    align-items:center;
    overflow:hidden;

    background:var(--lightred);
    color:var(--white);
}

.paid{
    background:var(--green);
}

.payment-info{
    overflow:scroll;
    width:100%;
}



.package-container{
    width:100%;
    height:min-content;
    overflow: auto;
    display:flex;
    flex-direction:column;
    align-items:center;
    justify-content:start;
}

.package-hours{
    display:flex;
    justify-content:center;
    align-items: center;
}


/* .attendances{
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: start;

} */







.show_payments{

animation-name: show_payments;
animation-duration: var(--animation-time);
animation-timing-function: ease-in-out;
/* animation-delay: calc(var(--animation-time)*2); */
/* animation-iteration-count: iteration-count; */
animation-direction: normal;
animation-fill-mode: forwards;
/* max-width:fit-content;
max-height:fit-content; */
--height:80vh;
--width:100%;

--font-size:large;
--padding:5px;
--margin:5px;
--gap:5px;

}


@keyframes show_payments {
  0%{
    min-width:0%;
    width:0%;
    max-width:0%;
    min-height:0%;
    height:0%;
    max-height:0%;
    font-size:0px;
    padding:0px;
    margin:0px;
    gap:0px;
  }

  100%{
    min-width:var(--width);
    width:var(--width);
    max-width:var(--width);
    min-height:var(--height);
    height:var(--height) ;
    max-height:var(--height);
    font-size:var(--font-size);
    padding:var(--padding);
    margin:var(--margin);
    gap:var(--gap);

    
  }

}




.hide_payments{


animation-name: hide_payments;
animation-duration: var(--animation-time);
animation-timing-function: ease-in-out;
/* animation-delay: calc(var(--animation-time)*2); */

animation-direction: normal;
animation-fill-mode: forwards;
}




@keyframes hide_payments {
  0%{
    min-width:var(--width);
    width:var(--width);
    max-width:var(--width);
    min-height:var(--height);
    height:var(--height) ;
    max-height:var(--height);
    font-size:var(--font-size);
    padding:var(--padding);
    margin:var(--margin);
    gap:var(--gap);
  }

  100%{
    min-width:0%;
    width:0%;
    max-width:0%;
    min-height:0%;
    height:0%;
    max-height:0%;
    font-size:0px;
    padding:0px;
    margin:0px;
    gap:0px;
  }



  
}






.show_payment{


    animation-name: show_payment;
    animation-duration: var(--animation-time);
    animation-timing-function: ease-in-out;
    /* animation-delay: calc(var(--animation-time)*2); */
    /* animation-iteration-count: iteration-count; */
    animation-direction: normal;
    animation-fill-mode: forwards;

}




@keyframes show_payment{

    0%{
        width:150px;
        height:100px;

    }

    100%{
        width: 100%;
        height:min-content;
    }

}


.hide_payment{
    animation-name: hide_payment;
    animation-duration: var(--animation-time);
    animation-timing-function: ease-in-out;
    /* animation-delay: calc(var(--animation-time)*2); */

    animation-direction: normal;
    animation-fill-mode: forwards;
}

@keyframes hide_payment{

    0%{
    width: 100%;
    height:700px;
    }

    100%{
    width:150px;
    height:100px;

    }

}









</style>
