<template>
    <div class="video-link-container">
        <button @click.stop="generate_interact_link()">Generate InterAct Link</button>
        <div>or</div>
        <input v-model="data.link" placeholder="paste custom link here">
    </div>



</template>




<script setup>
    import {reactive,inject} from 'vue'
    import axios from 'axios'



    let data=reactive({
        link:'',
        group:{},
        user:{},
    })
    data=inject('video_link_data')



    async function generate_interact_link() {
        let response = await axios.post('/instructors/generate_video_link/',{group_id:data.group.id,user_id:data.user.id})
        data.link=response.data.link
    }

</script>



<style scoped>

    .video-link-container{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: min-content;
        height: auto;
    }



</style>