<template>
  <ErrorAlerts/>
  <!-- <Header/> -->
  <NavBar/>
  <router-view/>

  <link rel="stylesheet" href="https://fonts.googleapis.com/css2?family=Material+Symbols+Outlined:opsz,wght,FILL,GRAD@20..48,100..700,0..1,-50..200" />
  
</template>


<script setup>
  import axios from 'axios'
  import {createApp,ref,onBeforeMount} from 'vue'
  import {useStore} from 'vuex'
  import NavBar from '@/components/NavBar.vue'
  import ErrorAlerts from '@/components/ErrorAlerts.vue'
  import Header from '@/components/HeaderComponent.vue'

  import { useRouter } from 'vue-router'
  import VueGtag from 'vue-gtag';


  onBeforeMount( ()=>{
    console.log('before mounting everything once');
    let store = useStore()
    store.commit('initializeStore')


    }
  )

  const router = useRouter();

  const app = createApp();

// Initialize the gtag plugin
  app.use(VueGtag, {
    config: {
      id: 'G-S8H5RKPEH5', // Replace with your actual Google Analytics Measurement ID
    },
  }, router);


</script>



<style>
.material-symbols-outlined {
  font-variation-settings:
  'FILL' 0,
  'wght' 600,
  'GRAD' 0,
  'opsz' 24
}
</style>

<style media="screen">

  @import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&family=Passion+One&family=Satisfy&family=Ubuntu+Condensed&display=swap');


  *{

    
    
    margin: 0;
    padding: 0;
    /* transition:all var(--transition-time) ease-in-out; */

    
    --bebas: 'Bebas Neue', sans-serif;
    --passion: 'Passion One', cursive;
    --satisfy: 'Satisfy', cursive;
    --ubuntu: 'Ubuntu Condensed', sans-serif;
    
    font-family:var(--ubuntu);

    --border:5px solid var(--black);
    --inactive-border:2px solid var(--lightinactive);
    --active-border:2px solid var(--lightactive);

    --end-border: 8px solid var(--orange);


    --shadow:var(--black) 5px 5px 2px;
    --active-shadow:var(--darkyellow) -5px -5px 2px, 
                    var(--lightactive) 5px 5px 2px;
    
/* ######################################### THEME ######################################### */
    
    --darkinactive:#0000007a;
    --inactive:#5352527a;
    --lightinactive:#c2c2c27a;

    --darkactive: #2b2828;
    --active: #3d3a3a;
    --lightactive: #c9b7ba;

    --black: #36363f;
    --gray:#9b9b9b;
    --white: #ece3db;


    --light:#d4dce2;

    --darkblue: #253C59;
    --blue: #2D4B73;
    --lightblue: #06739e;
    
    --darkyellow: #b89215;
    --yellow: #f7c608ff;
    --lightyellow: #ffd753ff;

    --darkorange: #c02d08;
    --orange: #d45421;
    --lightorange: #ec5845;
    
    --darkred: #752328;
    --red: #a82932;
    --lightred: #aa1942;
    
    
    --darkgray:#535353;    
    --gray:#7c7c7c;
    --lightgray1: #96a9b9;

    --darkgreen:#388a78;
    --green:#2ca68c;
    --lightgreen:#40bfa4;


/* ######################################### TRANSITIONS ######################################### */
    --transition-time:0.5s;
    --transition:all var(--transition-time) ease-in-out;

/* ######################################### ANIMATIONS ######################################### */

    --home:2s;
    --animation-time:0.5s;
    

  }






  html{
    margin: 0;
    padding: 0;
    width: 100vw;
    height: 100vh;
    background:var(--white);
  }

  .title{
    font-size:75px;
    width: 100%;
    text-align:center;
    white-space: nowrap;
  }
  .subtitle{
    font-size:35px;
    width: min-content;
    text-align:center;
    white-space: nowrap;
  }

  table{
    border-collapse:collapse;
    width: fit-content;
    text-align: center;
    align-self:center;
    justify-self:center;
    
  }
  .title, .subtitle,tr *,td *,th *, table *{
    transition:unset;

  }
  
  @media ( max-width: 900px){
    table{
      align-self: start;
    }
  }

  tr:nth-child(2n) {
    backdrop-filter:contrast(40%);

  }


  th{
    text-align: center;
    text-justify: center;
  }

  td{
    min-width: max-content;
    padding: 2px;

  }

  td table tr{
    border-bottom:unset;
  }

  thead, tr {
    border-bottom:var(--border);
  }
  

  button{
    all:unset;
    /* transition: var(--transition); */
    cursor:pointer;

    background: var(--inactive);
    color:var(--white);
    border:var(--inactive-border);
    box-shadow:var(--shadow);
    min-width:max-content;
    width:max-content;
    height: min-content;
    min-height: max-content;
    padding:2px;
    word-break: break-all;
    overflow:hidden;

  }
  
  button:hover{
    background:var(--active);
    box-shadow:var(--active-shadow);
    color:var(--white);
    /* border:var(--active-border); */

  }


  .confirmed{
    background:var(--green);
  }
  .cancelled-late{
    background:var(--red);
  }

  .no-show{
    background:var(--darkred);
  }

  .cancelled-on-time{
    background: var(--lightblue);
  }

  .rescheduled{
    background: var(--gray);
  }









/* ########################################## HIDE/SHOW ANIMATIONS ######################################### */



.show{

animation-name: show;
animation-duration: var(--animation-time);
animation-timing-function: ease-in-out;
/* animation-delay: calc(var(--animation-time)*2); */
/* animation-iteration-count: iteration-count; */
animation-direction: normal;
animation-fill-mode: forwards;
max-width:fit-content;
max-height:fit-content;
--height:100%;
--width:100%;

--font-size:large;
--padding:5px;
--margin:5px;
--gap:5px;

}





@keyframes show {
  0%{
    min-width:0%;
    width:0%;
    max-width:0%;
    min-height:0%;
    height:0%;
    max-height:0%;
    font-size:0px;
    padding:0px;
    margin:0px;
    gap:0px;
  }

  100%{
    min-width:var(--width);
    width:var(--width);
    max-width:var(--width);
    min-height:var(--height);
    height:var(--height) ;
    max-height:var(--height);
    font-size:var(--font-size);
    padding:var(--padding);
    margin:var(--margin);
    gap:var(--gap);

    
  }

}




.hide{


animation-name: hide;
animation-duration: var(--animation-time);
animation-timing-function: ease-in-out;
/* animation-delay: calc(var(--animation-time)*2); */

animation-direction: normal;
animation-fill-mode: forwards;
}




@keyframes hide {
  0%{
    min-width:var(--width);
    width:var(--width);
    max-width:var(--width);
    min-height:var(--height);
    height:var(--height) ;
    max-height:var(--height);
    font-size:var(--font-size);
    padding:var(--padding);
    margin:var(--margin);
    gap:var(--gap);
  }

  100%{
    min-width:0%;
    width:0%;
    max-width:0%;
    min-height:0%;
    height:0%;
    max-height:0%;
    font-size:0px;
    padding:0px;
    margin:0px;
    gap:0px;
  }



  
}



  


</style>
