<template>
    <div class="title">
        {{ user.ui }}
    </div>

    <div class="container attendance">
        <table class="students">
            <thead>
                <th>Instructor</th>
                <!-- <th>Material</th> -->
                <th>Horario</th>
                <th>Duration</th>
                <th>Status/Link de Clase</th>
            </thead>
            <tbody>
                <tr class="group" :class="{confirmed:group.confirmed,cancelled_late:group.cancelled_late,no_show:group.no_show,cancelled_on_time:group.cancelled_on_time,rescheduled:group.rescheduled}" v-for="group in data.groups">
                    <td>
                        <template v-for="instructor in group.instructor">
                            {{ instructor.ui }}

                        </template>
                    </td>
                    <td>{{ group.schedule }}</td>
                    <td>{{ group.duration }}</td>
                    <td>
                        <template v-if="group.confirmed">
                            <button @click.stop="join_class(group)">
                                Unirse a CLASE
                            </button>
                        </template>
                        <template v-else-if="group.cancelled_late">
                            Clase Cancelada Tarde
                        </template>
                        <template v-else-if="group.no_show">
                            Clase No Asistida
                        </template>
                        <template v-else-if="group.cancelled_on_time">
                            Clase Cancelada a Tiempo
                        </template>
                        <template v-else-if="group.rescheduled">
                            Clase Reagendada
                        </template>
                        <template v-else>
                            Link de clase no generado
                        </template>
                    </td>
                </tr>
            </tbody>

        </table>


    </div>

    <div class="title">
        Control de Clases para {{ user.ui }}
    </div>

    <div class="container payments">
        <template v-for="payment in data.payments">
            <div class="payment-container" :class="{paid:payment.paid}">
                <template v-for="package_ in payment.package">
                    <div class=payment>
                        <div>{{ package_.taken_hours }} / {{ package_.package_hours }}</div>
                        <div>Cantidad: {{payment.amount}}</div>
                        <template v-if="payment.paid">
                            <div>Fecha: {{payment.datetime}}</div>
                        </template>
                    </div>
                    <table class="package">
                        <thead>
                            <th>Fecha</th>
                            <th>Instructor</th>
                            <th>Alumno</th>
                            <th>Estatus</th>
                            <th>Duración</th>
                        </thead>
                        <tbody>
                            <tr v-for="attendance in package_.attendance">
                                <td>{{ attendance.datetime }}</td>
                                <td>{{ attendance.instructor_confirmation.at(-1).instructor.ui }} </td>
                                <td>{{ attendance.student_confirmation.at(-1).student.ui }}</td>
                                <td>{{ attendance.student_confirmation.at(-1).confirmation }}</td>
                                <td>{{attendance.duration}}</td>
                            </tr>
                        </tbody>
                    </table>
                </template>
            </div>
        </template>
    </div>
</template>


<script setup>
    import { ref, reactive, onBeforeMount,onMounted} from 'vue'
    import axios from 'axios'
    import {useStore} from 'vuex'
    import {useRoute,useRouter} from 'vue-router'

    let router = useRouter()

    let route=useRoute()


    let store=useStore()
    let user=store.state.user

    let data=reactive({
        groups:[],
        user:{},
        payments:[],
    })


    onBeforeMount(async ()=>{
        if (user.role.includes('Admin')){
            let user_id=route.query.user_id
            
            let response= await axios.post('/admin/get_user/',{user_id:user_id})
            user=response.data
            data.user=user

        }

        get_groups()
        get_payments()
        setInterval(()=>{
            if(route.name.includes('student-dashboard')){
                update_groups()
            }

        },10000)

    })


    async function get_payments(){
        let response=await axios.post('students/get_payments/',{user_id:user.id})
        Object.assign(data.payments,response.data)
    }

    async function get_groups(){
        let response=await axios.post('/students/get_groups/',{user_id:user.id})
        Object.assign(data.groups,response.data)

    }

    async function update_groups(){
        let response = await axios.post('/students/get_groups/',{user_id:user.id})
        let groups=response.data        
        for ( let key in data.groups){
            for (let element of groups){
                if (element.id==data.groups[key].id){
                let status_show=data.groups[key].status_window_show
                let status_hide=data.groups[key].status_window_hide
                let current_attendance=data.groups[key].current_attendance

                Object.assign(data.groups[key],element)
                Object.assign(data.groups[key].current_attendance,{show:current_attendance.show,hide:current_attendance.hide})

                }
            }

        }
    }


    function join_class(group){
        let attendance_link=group.current_attendance.link
        window.location.href=attendance_link
        // router.push({ name: 'meet',params:{link:attendance_link}});
    }


</script>


<style scoped>

.container{
    width:100%;
    display:flex;
    flex-direction:column;
    align-items:center;
    justify-content:center;
    background:var(--blue);
    color: var(--white);
  }

  .container:nth-of-type(2n){
    background:var(--darkyellow);
  }

  .attendance{
    max-height:500px;
    height: 500px;
    min-height: 500px;
  }

  .payments{
    gap:1rem;
    overflow:auto;
    padding-top:1rem;
  }

  .payment-container{
    background:var(--lightred);
    display:flex;
    flex-direction:column;
    min-width:max-content;
    padding:0 20px 5px 20px;

  }



  .payment{
    display:flex;
    flex-direction:column;
    justify-content:center;
    align-items:center;
  }

 

  .package{
    min-width:500px;
    width:fit-content;
    max-width:500px;
    overflow:auto;
    background: var(--light);
    color:var(--black);
  }


  .paid{
    background:var(--green);
  }


  tr{
    border:var(--border);
  }



  .group{
    height:300px;
  }

</style>